import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Model {
    id: number;
    family: string;
    family_id: number;
    internal_name: string;
    display_name: string;
    context_max_tokens: number;
    prompt_cost: number;
    completion_cost: number;
    base_cost: number;
    base_tokens: number;
    default_token_credit: number;
    default_cost_multiplier: number;
    released_at: number;
}

export interface ModelFamily {
    id: number;
    family: string;
    display_name: string;
}

export interface Billing {
    id: number;
    model_enabled: boolean;
    billing_enabled: boolean;
    timestamp: number;
    paid_tokens: number;
    common_bonus_tokens: number;
    miniapp_bonus_tokens: number;
    cost_multiplier: number;
    user_id: string;
    family_id: number;
}

export interface BillingState {
    models: Model[];
    modelsLoaded: boolean;
    billings: Billing[];
    billingsLoaded: boolean;
}

const initialState: BillingState = {
    models: [],
    modelsLoaded: false,
    billings: [],
    billingsLoaded: false,
};

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        receiveModels: (state, action: PayloadAction<Model[]>) => {
            state.models = action.payload;
            state.modelsLoaded = true;
        },
        receiveBillings: (state, action: PayloadAction<Billing[]>) => {
            state.billings = action.payload;
            state.billingsLoaded = true;
        },
        receiveBilling: (state, action: PayloadAction<Billing>) => {
            const billing = action.payload;

            if (state.billings.some(u => u.id === billing.id)) {
                state.billings = state.billings.map(u => u.id === billing.id ? billing : u);
            }
            else {
                state.billings.push(billing);
            }
        },
    },
});

export default billingSlice.reducer;
export const { receiveModels, receiveBillings, receiveBilling } = billingSlice.actions;