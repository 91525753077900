import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Bot {
    id: number;
    name: string;
    start_message: string;
}

export interface BotState {
    bots: Bot[];
    botsLoaded: boolean;
    activeBotId: Bot['id'];
}

const initialState: BotState = {
    bots: [],
    botsLoaded: false,
    activeBotId: -1,
};

export const botSlice = createSlice({
    name: 'bot',
    initialState,
    reducers: {
        receiveBots: (state, action: PayloadAction<Bot[]>) => {
            state.bots = action.payload;
            state.botsLoaded = true;
        },
        setActiveBotId: (state, action: PayloadAction<Bot['id']>) => {
            state.activeBotId = action.payload;
        },
    },
});

export default botSlice.reducer;
export const { receiveBots, setActiveBotId } = botSlice.actions;