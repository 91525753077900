import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from '../features/app/app-slice';
import userReducer from '../features/users/users-slice';
import presetReducer from '../features/chatgpt_preset/preset-slice';
import billingReducer from '../features/billing/billing-slice';
import botReducer from '../features/bot/bot-slice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        users: userReducer,
        presets: presetReducer,
        billing: billingReducer,
        bot: botReducer,
    },
});

// export type AppDispatch = typeof store.dispatch; // useless after migration to CoreUI for some reason
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
