import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
    id: string;
    bot_id: number;
    name: string;
    is_system: boolean;
    discord_id: string | null;
    telegram_id: number | null;
    vk_id: number | null;
    bill_from: number;
    token_credit: number;
    bill_multiplier: number;
    created_at: number;
    alias: string | null;
    lang_code: string;
    max_concurrency: number;
}

export interface FinishingPhrase {
    user_id: string;
    event_type: EventType;
    phrase: string;
}

export enum EventType {
    Join = 'join',
    Leave = 'leave',
}

export type ExternalUser = {
    source: ExternalSource.Telegram
    telegram_id: number;
    name: string;
    bot_id: number;
} | {
    source: ExternalSource.Discord
    discord_id: string;
    name: string;
}

export interface Chat {
    id: number;
    name: string;
    telegram_id: number | null;
    discord_id: number | null; // TODO actually string | null as well?
    is_whitelisted: boolean;
    ignore_prompts: boolean;
    bot_id: number;
}

export type ChatType = 'pm' | 'group';

export type ExternalChat = {
    source: ExternalSource.Telegram
    telegram_id: number;
    name: string;
    bot_id: number;
} | {
    source: ExternalSource.Discord
    discord_id: string;
    name: string;
}

export enum ExternalSource {
    Telegram = 0,
    Discord = 1,
}

export interface Usage {
    billing_id: number;
    user_id: string;
    family_id: number;
    tokens: number;
    cost: number;
    user_name: string;
    family_name: string;
    last_message: number | null;
}

export interface AppState {
    users: User[];
    externalUsers: ExternalUser[];
    isUsersLoaded: boolean;
    phrases: FinishingPhrase[];
    isPhrasesLoaded: boolean;
    chats: Chat[];
    externalChats: ExternalChat[];
    isChatsLoaded: boolean;
}

export interface ChatGptConversation {
    id: string;
    user_id: string;
    scope_type: number;
    scope_id: string;
    timestamp: number;
    tokens_total: boolean;
    user_family_id: number;
}

export interface ChatGptMessage {
    conversation_id: string;
    role: ChatRole;
    content: string;
    tokens: number;
    timestamp: number;
}

export enum ChatRole {
    System = 'system',
    User = 'user',
    Assistant = 'assistant',
}

const initialState: AppState = {
    users: [],
    externalUsers: [],
    isUsersLoaded: false,
    phrases: [],
    isPhrasesLoaded: false,
    chats: [],
    externalChats: [],
    isChatsLoaded: false,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        receiveUsers: (state, action: PayloadAction<[User[], ExternalUser[]]>) => {
            state.users = action.payload[0];
            state.externalUsers = action.payload[1];
            state.isUsersLoaded = true;
        },
        receiveUser: (state, action: PayloadAction<User>) => {
            const user = action.payload;

            if (state.users.some(u => u.id === user.id)) {
                state.users = state.users.map(u => u.id === user.id ? user : u);
            }
            else {
                state.users.push(user);
            }
        },
        removeUser: (state, action: PayloadAction<User>) => {
            state.users = state.users.filter(u => u.id !== action.payload.id);
        },
        receivePhrases: (state, action: PayloadAction<FinishingPhrase[]>) => {
            state.phrases = action.payload;
            state.isPhrasesLoaded = true;
        },
        receiveChats: (state, action: PayloadAction<[Chat[], ExternalChat[]]>) => {
            state.chats = action.payload[0];
            state.externalChats = action.payload[1];
            state.isChatsLoaded = true;
        },
        receiveChat: (state, action: PayloadAction<Chat>) => {
            const chat = action.payload;

            if (state.chats.some(u => u.id === chat.id)) {
                state.chats = state.chats.map(u => u.id === chat.id ? chat : u);
            }
            else {
                state.chats.push(chat);
            }
        },
        removeChat: (state, action: PayloadAction<Chat>) => {
            state.chats = state.chats.filter(u => u.id !== action.payload.id);
        },
    },
});

export default usersSlice.reducer;
export const { receiveUsers, receiveUser, removeUser, receivePhrases, receiveChats, receiveChat, removeChat } = usersSlice.actions;