import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppSettings {
    default_bot_id: number;
}

export enum AppStateEnum {
    Initial = 0,
    ServerDown = 1,
    Unathorized = 2,
    Initialized = 3,
}

interface UIState {
    sidebarShow: boolean
    sidebarUnfoldable: boolean
    asideShow: boolean
    theme: string
}

export interface AppState {
    state: AppStateEnum;
    settings: AppSettings;
    ui: UIState;
}

const initialState: AppState = {
    state: AppStateEnum.Initial,
    settings: {
        default_bot_id: -1,
    },
    ui: {
        sidebarShow: true,
        sidebarUnfoldable: false,
        asideShow: false,
        theme: 'default',
    }
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        receiveAppState: (state, action: PayloadAction<AppStateEnum>) => {
            state.state = action.payload;
        },
        receiveSettings: (state, action: PayloadAction<AppSettings>) => {
            state.settings = action.payload;
        },
        updateUI: (state, action: PayloadAction<Partial<UIState>>) => {
            state.ui = {
                ...state.ui,
                ...action.payload,
            };
        },
    },
});

export default appSlice.reducer;
export const { receiveSettings, receiveAppState, updateUI } = appSlice.actions;
