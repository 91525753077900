import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Preset {
    id: number;
    name: string;
    system_message: string;
    user_message_template: string;
    next_user_message_template: string;
    type: PresetType;
    is_public: boolean;
    description: string;
    sort: number;
    category: string;
    bot_id: number;
}

export enum PresetType {
    AdminHashtag = 'admin-hashtag',
    AdminDefault = 'admin-default',
    JoinLeaveEvent = 'join-leave-event',
    RandomComment = 'random-comment',
    UserGlobal = 'user-global',
    // TODO UserCustom = 'user-custom',
}

export interface ChatPreset {
    chat_id: number;
    preset_id: number;
}

export interface AppState {
    presets: Preset[];
    presetsLoaded: boolean;
    chatPresets: ChatPreset[];
    chatPresetsLoaded: boolean;
}

const initialState: AppState = {
    presets: [],
    presetsLoaded: false,
    chatPresets: [],
    chatPresetsLoaded: false,
};

export const presetsSlice = createSlice({
    name: 'presets',
    initialState,
    reducers: {
        receivePresets: (state, action: PayloadAction<Preset[]>) => {
            state.presets = action.payload;
            state.presetsLoaded = true;
        },
        receivePreset: (state, action: PayloadAction<Preset>) => {
            const preset = action.payload;

            if (state.presets.some(u => u.id === preset.id)) {
                state.presets = state.presets.map(u => u.id === preset.id ? preset : u);
            }
            else {
                state.presets.push(preset);
            }
        },
        removePreset: (state, action: PayloadAction<Preset>) => {
            state.presets = state.presets.filter(u => u.id !== action.payload.id);
        },
        receiveChatPresets: (state, action: PayloadAction<ChatPreset[]>) => {
            state.chatPresets = action.payload;
            state.chatPresetsLoaded = true;
        },
        receiveChatsByPreset: (state, action: PayloadAction<[number, number[]]>) => {
            const preset_id = action.payload[0];
            const chat_ids = action.payload[1];

            state.chatPresets = [
                ...state.chatPresets.filter(cp => cp.preset_id !== preset_id),
                ...chat_ids.map(chat_id => ({ preset_id, chat_id })),
            ]
        },
    },
});

export default presetsSlice.reducer;
export const { receivePresets, receivePreset, removePreset, receiveChatPresets, receiveChatsByPreset } = presetsSlice.actions;
